import * as React from 'react';

import cl from 'classnames';
import format from 'date-fns/format';
import vi from 'date-fns/locale/vi';
import { StaticQuery, graphql, Link } from 'gatsby';
import get from 'lodash/get';
import take from 'lodash/take';
import styled from 'styled-components';
import { IconCourseArrowRight } from 'components/Icon/SVGIcons';
import { fromScreen } from 'utils/media-query/responsive.util';
import IconClose from 'shared/CourseLanding/icons/Close';

import { LazyImage } from 'components/LazyImage';
import BubbleTitle from 'shared/SchedulePage/BubbleTitle';
import { colorsV2 } from 'style/colors-v2';
import { useDeviceDetect3 } from 'utils/hooks/useDeviceQuery.hook';
import { MasonryLayout } from '../Layout/MasonryLayout';
import { Typography } from '../../components/DesignSystemV2/Typography';
import IconColorFB from './icons/IconColorFB';
import PopupButton from '@dolstaff/shared/es/PopupButton';

/* eslint-disable react/prop-types */
const ImgAvatar = styled(LazyImage)`
  max-width: 48px;
  max-height: 48px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const PopupButtonWrapper = styled(PopupButton)`
  overflow: scroll;
  padding: 24px 32px;
  width: calc(100% - 56px);
  height: 600px;
  ${fromScreen(776)} {
    width: 680px;
    height: 652px;
  }
`;

const Main = styled.div`
  .review-title {
    margin-bottom: 24px;
  }
  .title-detail-course {
    margin-bottom: 16px;
  }
  .layout-columns {
    .column.flex {
      border-bottom: 1px solid ${colorsV2.gray20};
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .see-more-review {
    margin-top: 32px;
    display: grid;
    place-items: center;
    button {
      width: 100%;
      background-color: transparent;
      border: 1px solid ${colorsV2.primary100};
      padding: 10px 0;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      color: ${colorsV2.primary100};

      transition: 0.2s all;
      cursor: pointer;
      &:hover {
        > p {
          color: ${colorsV2.primary80};
        }
      }
      &:active {
        transform: scale(0.98);
      }
    }
  }

  ${fromScreen(776)} {
    .title-detail-course {
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 32px;
    }
    .see-more-review {
      button {
        width: 344px;
      }
    }
    .review-title {
      margin-bottom: 40px;
    }
  }

  ${fromScreen(1144)} {
    .review-title {
      text-align: center;
    }
  }
`;

const HeaderSectionNoLink = styled.div`
  padding: 16px 16px 0 16px;
  display: grid;
  gap: 4px;
  > div {
    margin: 0 auto;
    text-align: center;
  }
  svg {
    display: none;
  }
  .card-review-header-avatar {
    img {
      border-radius: 50%;
    }
  }
  .card-review-header-info {
    .date {
      display: none;
    }
  }
`;

const HeaderSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 20px;
  grid-column-gap: 16px;
  align-items: center;
  .card-review-header-avatar {
    img {
      border-radius: 50%;
    }
  }
  .card-review-header-info {
    .date {
      color: ${colorsV2.black80};
    }
  }
`;
const ImageSection = styled.div`
  img {
    width: 100%;
    height: 220px;
    object-fit: cover;
  }
`;
const DescriptionSection = styled.div`
  .card-review-footer {
    color: ${colorsV2.blue100};
  }
`;
const ClamppedText = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  &.text-only {
    -webkit-line-clamp: 15;
  }
  overflow: hidden;
`;
const ReviewCardWrapper = styled.div`
  min-height: 432px;
  position: relative;
  cursor: pointer;
  background-color: ${colorsV2.white100};
  border: 1px solid ${colorsV2.gray40};
  border-radius: 8px;
  ${HeaderSection} {
    padding: 16px 16px 0 16px;
  }
  ${ImageSection} {
    margin-top: 16px;
  }
  ${DescriptionSection} {
    margin-top: 16px;
    padding: 0 16px 16px 16px;
  }
  .hover-state {
    border-radius: 8px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    position: absolute;
    background: rgb(0 0 0 / 40%);
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    transition: 0.2s ease;
    color: ${colorsV2.white100};
    .see-more {
      display: flex;
      align-items: center;
    }
  }

  &:hover {
    .hover-state {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const ModalWrapper = styled.div`
  .modal-header {
    display: flex;
    justify-content: space-between;
    .icon {
      color: ${colorsV2.gray100};
      font-size: 32px;
      line-height: 0;
      cursor: pointer;
      &:hover {
        color: ${colorsV2.primary40};
      }
    }
  }
  .info {
    margin-top: 24px;
    margin-bottom: 16px;
    display: grid;
    gap: 4px;
    > div {
      margin: 0 auto;
      text-align: center;
    }
    .card-review-header-avatar {
      img {
        border-radius: 50%;
      }
    }
    .card-review-header-info {
      .date {
        display: none;
      }
    }
  }
  img {
    width: 100%;
  }
  .review-content {
    margin-top: 16px;
  }
`;

const HoverLayer = ({ handleOnClick = null }) => {
  return (
    <div className="hover-state" onClick={handleOnClick}>
      <Typography
        variant="semi-bold/14-20"
        className="see-more"
        color={colorsV2.white100}
      >
        Xem review <IconCourseArrowRight />
      </Typography>
    </div>
  );
};

const ReviewCard = ({
  avatarLink,
  fullName,
  dateFormatted,
  reviewContent,
  reviewLink,
  linkImage,
  title
}) => {
  const Header = reviewLink ? HeaderSection : HeaderSectionNoLink;

  return (
    <>
      <PopupButtonWrapper
        hideCloseButton
        hideOnBackdropClick
        isCentered
        zIndex={600}
        trigger={({ toggleContent }) => (
          <ReviewCardWrapper>
            <Header>
              <div className="card-review-header-avatar">
                <ImgAvatar
                  imgProps={{
                    alt: 'student-review'
                  }}
                  width={48}
                  height={48}
                  handle={avatarLink}
                />
              </div>
              <div className="card-review-header-info">
                <Typography variant="semi-bold/14-20" className="title">
                  {fullName}
                </Typography>
                <Typography variant="regular/14-20" className="date">
                  {dateFormatted}
                </Typography>
              </div>
              {reviewLink && <IconColorFB width={20} height={20} />}
            </Header>
            {linkImage && (
              <ImageSection>
                <LazyImage
                  imgProps={{
                    alt: 'student-image'
                  }}
                  handle={linkImage}
                />
              </ImageSection>
            )}
            <DescriptionSection>
              <ClamppedText className={cl({ 'text-only': !linkImage })}>
                <Typography variant="regular/14-20">{reviewContent}</Typography>
              </ClamppedText>
            </DescriptionSection>
            {reviewLink ? (
              <Link
                href={reviewLink}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <HoverLayer />
              </Link>
            ) : (
              <HoverLayer handleOnClick={toggleContent} />
            )}
          </ReviewCardWrapper>
        )}
      >
        {({ toggleContent }) => (
          <ModalWrapper>
            <div className="modal-header">
              <div>
                <Typography variant="bold/24-32">{title}</Typography>
              </div>
              <div onClick={toggleContent} className="icon">
                <IconClose />
              </div>
            </div>
            <div className="info">
              <div className="card-review-header-avatar">
                <ImgAvatar
                  imgProps={{
                    alt: 'student-review'
                  }}
                  width={48}
                  height={48}
                  handle={avatarLink}
                />
              </div>
              <div className="card-review-header-info">
                <Typography variant="semi-bold/14-20" className="title">
                  {fullName}
                </Typography>
                <Typography variant="regular/14-20" className="date">
                  {dateFormatted}
                </Typography>
              </div>
            </div>
            {linkImage && (
              <LazyImage
                imgProps={{
                  alt: 'student-image'
                }}
                handle={linkImage}
              />
            )}
            <Typography className="review-content" variant="regular/14-20">
              {reviewContent}
            </Typography>
          </ModalWrapper>
        )}
      </PopupButtonWrapper>
    </>
  );
};

// const limitReview = 6;

export const StudentCourseReviews = ({
  data,
  bubble,
  columns = 3,
  title,
  titleDetailCourse,
  noTitle = false
}) => {
  const [page, setPage] = React.useState(0);
  const { isMobile } = useDeviceDetect3();
  let limitReview;
  if (isMobile) {
    limitReview = 3;
  } else {
    limitReview = 6;
  }
  const maxPage = Math.floor(data.length / limitReview);
  const reviewPaged = take(data, (page + 1) * limitReview);
  const remainItems = data.length - (page + 1) * limitReview;

  const sectionTitle = React.useMemo(() => {
    if (noTitle) {
      return null;
    }

    if (title) {
      return (
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          className="review-title"
          as="h2"
        >
          {title}
        </Typography>
      );
    }

    if (titleDetailCourse) {
      return (
        <Typography
          variant="bold/20-28"
          desktopVariant="bold/24-32"
          v3
          className="title-detail-course"
          as="h2"
        >
          {titleDetailCourse}
        </Typography>
      );
    }

    return (
      <BubbleTitle
        data={
          bubble || [{ type: 'text', text: '❣ Tâm tình học viên dành cho DOL' }]
        }
      />
    );
  }, [noTitle, title, titleDetailCourse, bubble]);

  if (data.length === 0) return null;

  return (
    <Main id={'course-landing-student-reviews'}>
      {sectionTitle}

      <MasonryLayout breakpointCols={columns}>
        {reviewPaged.map(review => {
          const {
            id,
            reviewDate,
            fullName,
            reviewLink,
            avatarLink,
            coverLink,
            reviewContent
          } = review.node;
          const dateFormatted = format(new Date(reviewDate), 'MM/yyyy', {
            locale: vi
          });
          return (
            <ReviewCard
              title={sectionTitle}
              key={id}
              dateFormatted={dateFormatted}
              fullName={fullName}
              avatarLink={avatarLink}
              linkImage={coverLink}
              reviewLink={reviewLink}
              reviewContent={reviewContent}
            />
          );
        })}
      </MasonryLayout>

      {remainItems > 0 && (
        <div className="see-more-review">
          <button
            disabled={page === maxPage}
            onClick={() => setPage(prevPage => prevPage + 1)}
          >
            <Typography variant="semi-bold/14-24" color={colorsV2.primary100}>
              Xem thêm review
            </Typography>
          </button>
        </div>
      )}
    </Main>
  );
};

const studentOnlineCourseFacebookReviewsQuery = graphql`
  query allGoogleSpreadsheetDolLandingPageReviewAllNewQuery {
    allGoogleSpreadsheetDolLandingPageReviewAllNew(
      filter: { listing: { eq: "listed" } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          id
          reviewLink
          avatarLink
          coverLink
          fullName
          reviewDate
          reviewContent
          listing
          program
          order
        }
      }
    }
  }
`;

/**
 *
 * columns: { 775: 1, 1143: 2, default: 3 }
 *
 * 775 and 1143: max-width
 * default: largest screen
 *
 * **/

const StudentReviewsMain = ({
  program,
  bubble,
  columns,
  title,
  titleDetailCourse,
  noTitle = false
}) => {
  return (
    <StaticQuery
      query={studentOnlineCourseFacebookReviewsQuery}
      render={data => {
        let reviews =
          data.allGoogleSpreadsheetDolLandingPageReviewAllNew.edges.filter(
            item => {
              let isValidClassType = item.node.program === program;
              const isNotBlank =
                (get(item, 'node.reviewContent', '') || '').trim().length > 0;
              if (program === 'all') isValidClassType = true;
              return isValidClassType && item.node.avatarLink && isNotBlank;
            }
          );
        if (reviews.length === 0) {
          reviews =
            data.allGoogleSpreadsheetDolLandingPageReviewAllNew.edges.filter(
              item => {
                const isNotBlank =
                  (get(item, 'node.reviewContent', '') || '').trim().length > 0;
                return item.node.avatarLink && isNotBlank;
              }
            );
        }

        return (
          <StudentCourseReviews
            bubble={bubble}
            data={reviews}
            columns={columns}
            title={title}
            titleDetailCourse={titleDetailCourse}
            noTitle={noTitle}
          />
        );
      }}
    />
  );
};

export default StudentReviewsMain;
